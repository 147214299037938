import React from 'react'

import * as st from '~/assets/styl/404.module.styl'
import logo from '~/assets/svg/logo-white.svg'

import { Link } from 'gatsby'

const NotFound = () => (
  <>
    <div className={st.notFoundWrapper}>
      <section className={st.notFound}>
        <img src={logo} alt="Logo do Guia Pointer" />
        <h2>Página não encontrada</h2>
        <p>
          A página que você tentou acessar não existe ou está fora do ar
          temporariamente.
        </p>
        <div className={st.buttonWrapper}>
          <Link to="/" className={st.back}>
            Ir para a home
          </Link>
        </div>
      </section>
    </div>
  </>
)

export default NotFound
